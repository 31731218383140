import './styles.scss';

import * as yup from 'yup';

import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { setPassword, validateCode } from '../actions/authActions';

import AuthStateProvider from '../components/authStateProvider';
import FormField from '../components/form/formField';
import Header from '../components/header/header';
import SEO from '../components/SEO';
import { isEqual } from 'lodash';

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required.'),
  code: yup.string().required('Reset code is required.'),
  validated: yup.boolean(),
  password: yup.string().when('validated', {
    is: true,
    then: yup.string().required('Password is a required field.'),
  }),
});

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [validateResult, setValidate] = useState('null'); // oneOf(['ok', 'fail', null])

  const formRef = useRef();

  // useEffect(() => {
  //     if(formRef.current) {
  //         formRef.current.setFieldValue('validated', true)
  //     }
  // }, [formRef])

  const handleVerifyCode = async ({ email, code, validated, password }) => {
    if (!validated && isEqual(validateResult, 'null')) {
      const validationResult = await validateCode(email, code);
      if (validationResult.status) {
        formRef.current.setFieldValue('validated', true);
        setValidate('ok');
      } else {
        formRef.current.setFieldValue('validated', false);
        setValidate('fail');
      }
    }
    if (validated && isEqual(validateResult, 'ok')) {
      const resetResult = await setPassword(email, code, password);
      if (resetResult.status) {
        //  Password successfully Changed
      } else {
        //Password reset failed
      }
    }
  };

  return (
    <AuthStateProvider authRequired={false} className="main-container bg-grey h-100" {...props}>
      <SEO title="Reset Password" />
      <Header hideMiddle={true} show={true} />
      <div className="h-100">
        <div className="bg-white forgot-inner-container">
          <div className="login-title dsp-flx flx-col flx-center">
            <h3 className="fnt-evolventa bold fnt-primary">Reset your Password</h3>
            <p className="fnt-evolventa desc fnt-r-16 text-align-center">
              Provide the reset code that was sent to your email.
            </p>
            <div className="mt-5 form-wrap">
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  email: '',
                  code: '',
                  validated: false,
                  password: '',
                }}
                onSubmit={handleVerifyCode}
                innerRef={formRef}
              >
                {({ values, errors, handleChange, handleSubmit, setFieldValue, setValues }) => (
                  <form onSubmit={handleSubmit}>
                    <FormField
                      required
                      value={values.email}
                      name="email"
                      label="Email"
                      onChange={handleChange}
                      containerClass="form-control-text"
                    />
                    <ErrorMessage name="email" />
                    <FormField
                      required
                      value={values.code}
                      name="code"
                      label="Code"
                      onChange={handleChange}
                      className="mt-4"
                      containerClass="form-control-text"
                    />
                    <ErrorMessage name="code" />
                    <input value={values.validated} hidden />
                    {isEqual(validateResult, 'ok') ? (
                      <>
                        <FormField
                          required
                          value={values.password}
                          name="password"
                          label="Password"
                          type="password"
                          onChange={handleChange}
                          className="mt-4"
                          containerClass="form-control-text"
                        />
                        <ErrorMessage name="email" />
                      </>
                    ) : null}
                    <button
                      // disabled={loading}
                      className="login-confirm bg-prime p-3 fnt-white dsp-flx flx-center mt-4"
                      role="button"
                      type="submit"
                    >
                      <p className="m-0 fnt-evolventa fnt-weight-600">Reset Password </p>
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </AuthStateProvider>
  );
};

export default ResetPassword;
